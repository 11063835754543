@use "theme/variables.scss" as *;
.sectionFlagshipProgram {
  @include flexRow();
  justify-content: center;
  width: 100%;
  background: linear-gradient(#795ec6, #8cc5de);
  margin-bottom: 88px;
  .container {
    @include flexCol(32px);
    max-width: 1440px;
    padding: 60px 50px;
    width: 100%;
    justify-content: center;
    .description {
      @include flexCol(20px);
      justify-content: center;
      align-items: center;
      > div {
        position: relative;
        > h1 {
          color: $NEUTRAL-10;
          font-size: 40px;
          font-weight: 600;
          line-height: 50px;
          z-index: 2;
          position: relative;
        }
        .underline {
          position: absolute;
          left: 190px;
          top: 42px;
          z-index: 1;
        }
      }
      > p {
        color: $NEUTRAL-10;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .wrapperCard {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(478px, 2fr));
      gap: 20px;
      .card {
        background-color: $NEUTRAL-10;
        padding: 10px;
        border-radius: 12px;
        color: $NEUTRAL-100;
        @include flexCol(10px);
        cursor: pointer;
        .imagesBg {
          background-color: #dfedde;
          width: 100%;
          @include flexCol(0);
          align-items: center;
          justify-content: center;
          position: relative;
          border-radius: 8px;
          overflow: hidden;
          .wave {
            position: absolute;
            z-index: 1;
            bottom: -6px;
          }
          > img {
            width: 132px;
            height: 132px;
            object-fit: contain;
            z-index: 2;
          }
          &.education {
            background-color: #dfedde;
          }
          &.bootcamp {
            background-color: #d3c3fa;
          }
          &.corporation {
            background-color: #e6ffa8;
          }
          &.prakerja {
            background-color: #d4e8ff;
          }
        }
        .wrapperDescription {
          @include flexRow(12px);
          align-items: center;
          .description {
            @include flexCol(4px);
            align-items: flex-start;
            > h4 {
              color: $NEUTRAL-100;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
            }
            > p {
              text-align: start;
              color: $NEUTRAL-100;
              font-size: 12px;
              line-height: 16px;
            }
          }
          .roundedIcon {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            @include flexCol();
            align-items: center;
            justify-content: center;
            background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, #795ec6, #8cc5de) border-box;
            border-radius: 50em;
            border: 1px solid transparent;
            transition: 0.3s;
            &:hover {
              background: $NEW-PRIMARY2-BASE;
              .icon {
                color: $NEUTRAL-10;
              }
            }
          }
        }
      }
      .modalAlert {
        background-color: $NEUTRAL-10;
        max-width: 519px;
        width: 100%;
        padding: 24px;
        border-radius: 8px;
        .title {
          @include flexBetween();
          align-items: flex-start;
          gap: 8px;
          > h4 {
            color: $NEUTRAL-90;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }
          .closeIcon {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
        > p {
          color: $NEUTRAL-70;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
        > img {
          width: 100%;
          max-width: 465px;
          margin-top: 16px;
        }

        .whatsAppButton {
          cursor: pointer;
          width: fit-content;
          @include flexRow(6px);
          justify-self: flex-end;
          align-items: center;
          padding: 8px 16px;
          border-radius: 12px;
          background-color: #15cd3d;
          color: $NEUTRAL-10;
          margin-top: 16px;
          > span {
            font-size: 14px;
            font-weight: 600;
          }
        }
        @media screen and (max-width: 768px) {
          margin-inline: 1rem;
          padding: 1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sectionFlagshipProgram {
    margin-top: 40px;
    margin-bottom: 0;
    .container {
      padding: 24px 1rem;
      .wrapperCard {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      }
      .description {
        > div {
          > h1 {
            color: $NEUTRAL-10;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
          }
          .underline {
            position: absolute;
            left: 90px;
            top: 22px;
            z-index: 1;
          }
        }
        > p {
          text-align: center;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
