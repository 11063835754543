@use "theme/variables.scss" as *;
.wrapperImageGalleryPrakerja {
  background-color: #dfedde;
  width: 100%;
  padding-inline: 32px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  .wave {
    position: absolute;
    bottom: -35.2dvh;
    left: 0;
    z-index: 1;
  }
  .sectionLeft {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: 50%;
    z-index: 2;
    position: relative;
    top: -32px;
    // padding-top: 121px;
    @media (max-width: 1024px) {
      position: static;
    }
    .headerText {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      h1 {
        color: $NEUTRAL-100;
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
      }
      span {
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;
        background: var(
          --New-Primary-Gradient-Color-Gradient-2,
          linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .buttonAction {
      @include flexRow(1rem);
      .lastBtn {
        border: unset;
        background: transparent;
      }
      @media screen and (max-width: 768px) {
        gap: 8px;
        .btn {
          padding: 6px 12px;
          font-size: 14px;
        }
      }
    }
    .listFeatures {
      @include flexCol(20px);
      > div {
        @include flexRow(8px);
        align-items: center;
      }
    }
    // .generateVoucher {
    //   max-width: 386px;
    //   width: 100%;
    //   h1 {
    //     color: $NEUTRAL-100;
    //     font-size: 20px;
    //     font-weight: 600;
    //     line-height: normal;
    //   }
    //   p {
    //     color: $NEUTRAL-90;
    //     font-size: 14px;
    //     font-weight: 400;
    //     line-height: 160%;
    //   }
    //   .wrapperButton {
    //     padding-top: 12px;
    //     display: flex;
    //     gap: 15px;
    //     input[type="text"] {
    //       width: 247px;
    //       height: 44px;
    //       padding: 10px 11px;
    //       border-radius: 8px;
    //       border: 1px solid var(--Neutral-40, #e0e0e0);
    //       box-shadow: 0px 6px 24px 4px rgba(162, 166, 180, 0.25);
    //       backdrop-filter: blur(5.199999809265137px);
    //       font-size: 1rem;
    //       &::placeholder {
    //         color: $NEUTRAL-80;
    //         font-size: 16px;
    //         line-height: 20px;
    //       }
    //       &:focus {
    //         outline-color: $NEW-PRIMARY2-BASE;
    //       }
    //     }
    //     .button {
    //       max-width: 99px;
    //       padding-inline: 16px;
    //       height: 44px;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       border-radius: 8px;
    //       background: $NEUTRAL-10;
    //       box-shadow: 0 3px 1px $NEUTRAL-100;
    //       transition: 0.3s;
    //       cursor: pointer;
    //       span {
    //         color: $NEW-PRIMARY2-BASE;
    //         font-weight: 600;
    //       }
    //       &:active {
    //         box-shadow: none;
    //         transform: translateY(3px);
    //       }
    //     }
    //   }
    //   .errorMessage {
    //     color: $DANGER_MAIN;
    //     font-size: 14px;
    //     text-align: left;
    //   }
    // }
    .skillsXprakerja {
      display: flex;
      gap: 38px;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        gap: 8px;
        span {
          color: $NEUTRAL-90;
          font-size: 24px;
        }
      }
      span {
        font-size: 24px;
      }
      .imagesLogo {
        width: 40px;
        height: 50px;
        z-index: 99;
      }
      .imagesLogoPrakerja {
        width: 109x;
        height: 37px;
      }
    }
  }
  .sectionRight {
    width: 50%;
    position: relative;
    right: -2rem;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    .borderWrapper {
      top: 0;
      right: 0;
      width: 100%;
      flex-shrink: 0;
      border-bottom-left-radius: 30%;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .heroImage {
        max-width: 698px;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        z-index: 1;
        object-fit: contain;
      }
    }
  }
}

@media (max-width: 900px) {
  .wrapperImageGalleryPrakerja {
    padding-top: 24px;
    padding-inline: 1rem;
    @include flexCol(1rem);
    .wave {
      display: none;
    }
    .sectionRight {
      width: 100vw;
      left: 0;
      .borderWrapper {
        width: 100%;
        align-items: center;
        justify-content: center;
        .heroImage {
          max-width: unset;
          flex-grow: 1;
        }
      }
    }
    .sectionLeft {
      width: 100%;
      .headerText {
        h1 {
          font-size: 24px;
          line-height: 30px;
        }
        span {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}

@keyframes fadeOutTop {
  0%,
  100% {
    transform: translateY(1px) rotate(30deg);
  }
  50% {
    transform: translateY(-10px) rotate(30deg);
  }
}
