@use "theme/variables.scss" as *;
.wrapperConditionPrakerja {
  @include flexCol(42px);
  justify-content: center;
  align-items: center;
  .wrapperText {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 32px 0 32px;

    h1 {
      color: $NEUTRAL-100;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
  }
  .carouselImg {
    width: 100%;
    @include flexRow(32px);
    align-items: center;
    padding: 60px 32px 0 32px;
    max-width: 1440px;
    justify-self: center;
    width: 100%;
    .left {
      width: 40%;
      @include flexCol(24px);
      z-index: 2;
      .list {
        @include flexCol(8px);
        align-items: flex-start;
        cursor: pointer;
        transition: 0.3s;
        min-width: 580px; // 🔥
        width: 100%;
        height: auto;
        @media screen and (max-width: 1200px) {
          min-width: unset; // 🔥
        }
        &.active {
          cursor: text;
          border-left: 2px solid $NEW-PRIMARY2-BASE;
          padding-left: 40px;
          h4 {
            color: $NEUTRAL-100;
          }
          h2 {
            background: var(
              --New-Primary-Gradient-Color-Gradient-2,
              linear-gradient(98deg, #795ec6 0%, #8cc5de 100.02%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          // p {
          //   transition: 0.5s;
          // }
        }
        h4 {
          color: $NEUTRAL-50;
          font-size: 24px;
          font-weight: 400;
          line-height: 30px;
        }
        h2 {
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
          color: $NEUTRAL-60;
        }
        p {
          color: $NEUTRAL-100;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .right {
      width: 60%;
      display: flex;
      min-height: 651.5px;
      justify-content: flex-end;
      img {
        object-fit: contain;
        max-width: 770px;
        max-height: 651.5px;
        width: 100%;
        height: auto;
      }
    }
  }
  .borderWave {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 3;
    height: 40vh;
    @media screen and (max-width: 1200px) {
      height: 45vh;
    }
    justify-content: center;
    overflow: hidden;
    // @media screen and (max-width: 1200px) {
    //   height: 28vh;
    // }

    .newWave {
      width: 100vw;
      position: relative;
      height: 100% !important;
      object-fit: fill !important;
    }

    .viewRowBetween {
      // background-color: salmon;
      position: absolute;
      display: flex;
    }

    .wave {
      position: absolute;
      top: -10vw;
      // left: -2rem;
      z-index: 2;
      width: 100vw;
      height: 100vh;
      @media screen and (max-width: 1200px) {
        top: -20vw;
      }
      @media screen and (max-width: 1024px) {
        top: -20vw;
      }
    }
    .viewRowBetween {
      bottom: 0;
      position: absolute;
      z-index: 2;
      color: $NEUTRAL-10;
      @include flexBetween();
      gap: 1rem;
      padding-inline: 1rem;
      align-items: center;
      @media screen and (max-width: 1024px) {
        top: 5rem;
      }
      img {
        width: 160px;
        height: 158.273px;
        position: absolute;
        right: 10rem;
        top: -8.5rem;
      }
      .left {
        @include flexCol(8px);
        max-width: 500px;
        > h2 {
          color: $NEUTRAL-10;
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }
        > span {
          color: $NEUTRAL-10;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        }
      }
      .right {
        @include flexRow(26px);
        align-items: center;
        // @media (max-width: 1100px) {
        // display: grid;
        // grid-template-columns: auto auto;
        // grid-template-columns: repeat(auto-fill, minmax(600px, 2fr));
        // background-color: salmon;
        // }
        .viewColWithDivider {
          @include flexCol(8px);
          max-width: 254px;
          min-height: 180px;
          .divider {
            width: 100%;
            height: 2px;
            background-color: $NEUTRAL-30;
            margin-bottom: 8px;
          }
          .title {
            @include flexRow(8px);
            align-items: center;
            justify-content: flex-start;
            > div {
              display: flex;
              padding: 4px 8px;
              flex-direction: column;
              align-items: flex-start;
              align-items: center;
              justify-content: center;
              gap: 10px;
              background-color: $NEUTRAL-10;
              border-radius: 50%;
              width: 31px;
              height: 31px;
              flex-shrink: 0;
              color: $NEUTRAL-100;
              > span {
                font-size: 16px;
                font-weight: 600;
                background: var(
                  --New-Primary-Gradient-Color-Gradient-2,
                  linear-gradient(
                    98deg,
                    rgb(121, 94, 198) 0%,
                    rgb(140, 197, 222) 100.02%
                  )
                );
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
          > p {
            color: $NEUTRAL-10;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .wrapperConditionPrakerja {
    // padding: 1rem;
    padding-bottom: unset;
    // padding-top: 32px;
    row-gap: 1rem;
    .wrapperText {
      padding-inline: 1rem;
      padding-top: 32px;
      h1 {
        font-size: 24px !important;
        line-height: 30px;
      }
      p {
        font-size: 14px;
        line-height: 1.4rem;
      }
    }
    .carouselImg {
      @include flexCol(1rem);
      padding: 1rem;

      .left {
        order: 2;
        width: 100%;
        z-index: 2;
        row-gap: 1rem;
        .list {
          min-width: unset; // 🔥
          &.active {
            padding-left: 1rem;
          }
          h4 {
            font-size: 14px;
            line-height: 20px;
          }
          h2 {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
          }
          p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .right {
        order: 1;
        width: 100%;
        min-height: unset;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .borderWave {
      // margin-top: 4rem;
      overflow: hidden;
      height: 80vh;
      // margin-inline: -1rem;
      padding-inline: 1rem;
      .wave {
        height: 85vh;
        height: 110vh;
        width: 150vw;
        left: -40vw;
        top: 0;
      }
      .viewRowBetween {
        @include flexCol(1rem);
        padding-inline: 1rem;
        top: 9rem !important;
        img {
          right: 40px;
          @media (max-width: 490px) {
            top: -140px;
          }
        }
        .left {
          max-width: unset;
        }
        .right {
          @include flexCol(1rem);
          padding-bottom: 24px;
          .viewColWithDivider {
            max-width: unset;
            min-height: unset;
          }
        }
      }
    }
  }
}
