@import "../../../theme/variables.scss";
.containerStagesPrakerja {
  .wrapperStagesPrakerja {
    display: flex;
    gap: 2rem;
    padding: 79px 2rem;
    max-width: 1440px;
    justify-self: center;
    .leftText {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      position: sticky;
      top: calc(56px + 2rem);
      height: fit-content;
      div {
        color: $NEUTRAL-90;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
        span {
          background: $GRADIENT;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 32px;
          font-weight: 600;
          line-height: 40px;
        }
      }
      p {
        color: $NEUTRAL-90;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .rightText {
      display: grid;
      grid-template-columns: auto auto auto;
      row-gap: 32px;
      gap: 1rem;
      > div {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 1rem 10px;
        width: 265px;
        .lineTopGradient {
          width: 100%;
          height: 2px;
          background: $COLOR-GRADIENT-2;
        }
        h1 {
          color: $NEW-PRIMARY2-BASE;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;

          > span {
            background-color: $NEW-PRIMARY2-BASE;
            padding: 4px 10px;
            border-radius: 50%;
            color: $NEUTRAL-10;
            min-width: 31px;
            height: 28px;
            margin-right: 8px;
            font-size: 14px;
            font-weight: 600;
            flex-shrink: 0;
          }
        }
        p {
          color: $NEUTRAL-90;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .containerStagesPrakerja {
    .wrapperStagesPrakerja {
      padding-block: 50px;
      padding-inline: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0;
      .leftText {
        row-gap: 0.2rem;
        position: static;
        div {
          font-size: 24px;
          line-height: 30px !important;
          span {
            font-size: 24px;
            line-height: 30px !important;
          }
        }
      }
      .rightText {
        grid-template-columns: auto;
        gap: 8px;
        row-gap: 0.6rem;
        > div {
          row-gap: 0.8rem;
          width: 300px;
          padding-inline: 0;
        }
      }
    }
  }
}
