@use "theme/variables.scss" as *;
.describePrakerja {
  padding: 0;
  position: relative;
  box-shadow: 0 6px 5px #a9a9a940;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  background-color: $NEUTRAL-10;
  padding: 100px;
  z-index: 3;
  scroll-margin: 75px;
  img[alt="people-mockup"] {
    width: 438px;
    height: 438px;
    flex-shrink: 0;
    @media screen and (max-width: 460px) {
      width: 340px;
      height: 340px;
    }
  }

  .wrapperText {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    z-index: 2;
    h1 {
      color: $NEUTRAL-90;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      padding-bottom: 8px;
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      p {
        color: $NEUTRAL-90;
        font-size: 16px;
        line-height: 24px;
        max-width: 574px;
        white-space: pre-wrap;
        font-weight: 400;
        line-height: 24px;
      }
      div {
        .linkTo {
          width: max-content;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .describePrakerja {
    order: 1;
    padding: 32px 1rem 1rem 1rem;
    @include flexCol(4rem);
    overflow: hidden;
    @media (max-width: 768px) {
      row-gap: 0;
      align-items: center;
    }

    @media (max-width: 768px) {
      img[alt="people-mockup"] {
        order: 2;
      }
    }

    .wrapperText {
      row-gap: 1rem;
      h1 {
        line-height: 1rem;
        font-size: 24px !important;
      }
    }
    .text {
      p {
        font-size: 14px !important;
        width: auto !important;
        line-height: 20px !important;
      }
    }
    .button {
      align-self: center;
    }
  }
}
