@use "theme/variables.scss" as *;

.sectionFormContactUs {
  max-width: 1440px;
  background-color: $NEUTRAL-10;
  @include flexRow(32px);
  // align-items: center;
  position: relative;
  padding-right: 32px;
  scroll-margin: 78px;
  justify-self: center;
  width: 100%;
  z-index: 2;
  padding-top: 2rem;
  .left {
    width: 50%;
    height: auto;
    .bgElement {
      width: 618.778px;
      height: 713.403px;
      transform: rotate(16.112deg);
      flex-shrink: 0;
      border-radius: 60px;
      opacity: 0.5;
      background: var(--New-Primary-1-Primary-10, #e6e6f5);
      position: relative;
      left: -6rem;
      top: -8rem;
    }
    img[alt="contact-us"] {
      width: 193px;
      height: 193px;
      position: absolute;
      top: 20px;
      left: 320px;
    }
    img[alt="telpehone"] {
      position: absolute;
      top: 5rem;
      left: 200px;
      width: 481.257px;
      height: 481.257px;
      flex-shrink: 0;
    }
  }

  .leftSection {
    width: 50%;
    background-color: #e6e6f5;
    position: relative;
    @include flexCol();
    align-items: center;
    transform: rotate(20.112deg);
    border-radius: 60px;
    border-top-left-radius: 50%;
    left: -100px;
    top: -200px;
    height: 600px;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   width: 100%;
    //   background-color: salmon;
    // }
    .wrapImg {
      position: absolute;
      @include flexCol(0);
      // background-color: salmon;
      height: 100%;
      align-items: center;
      top: 0;
      left: 20vw;
      img[alt="contact-us"] {
        position: relative;
        width: 200px;
        height: 200px;
        // background-color: red;
        flex-shrink: 0;
        margin-top: 0;
        top: 8rem;
        // background-color: salmon;
      }
      img[alt="telpehone"] {
        width: 481.257px;
        height: 481.257px;
        // transform: rotate(-165deg);
        flex-shrink: 0;
        margin-top: 0;
      }
    }
    @media screen and (max-width: 1024px) {
      @include flexCol();
      left: -10rem;
      top: -100px;
      height: 400px;
      width: 100vw;
      // transform: rotate(40.112deg);
      .wrapImg {
        top: -10rem;
        left: 50vw;
      }
    }
    @media screen and (max-width: 768px) {
      height: 300px !important;
      .wrapImg {
        top: -8rem;
        left: 50vw;
        img[alt="contact-us"] {
          width: 140px;
          height: 140px;
        }
        img[alt="telpehone"] {
          width: 318.005px;
          height: 318.005px;
        }
      }
    }
  }

  .right {
    width: 50%;
    @include flexCol(32px);
    .headerForm {
      @include flexCol(8px);
      > p {
        color: $NEW-PRIMARY2-BASE;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
      }
      h2 {
        color: $NEUTRAL-100;
        font-size: 32px;
        font-weight: 600;
        line-height: 40px;
      }
    }
    .btn {
      width: fit-content;
      align-self: flex-end;
    }
  }
}

@media screen and (max-width: 1024px) {
  .sectionFormContactUs {
    @include flexCol(1rem);
    padding-right: unset;
    padding-inline: 1rem;
    .left {
      width: 100%;
      height: 100%;
      .bgElement {
        width: 416.24px;
        height: 250.893px;
        left: -10rem;
      }
      img[alt="telpehone"] {
        width: 218.005px;
        height: 218.005px;
        left: 0;
        top: 0;
        translate: 50% 20%;
      }
      img[alt="contact-us"] {
        width: 122.6px;
        height: 111.976px;
        left: 0;
        translate: 80% 10%;
      }
    }
    .right {
      width: 100%;
      @include flexCol(1rem);
      .headerForm {
        margin-bottom: calc(24px - 16px);
        p {
          font-size: 14px;
        }
        > h2 {
          font-size: 24px;
          font-weight: 600;
          line-height: 30px;
        }
      }
      .btn {
        width: 100%;
        margin-top: calc(24px - 16px);
      }
    }
  }
}
