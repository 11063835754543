@use "../../../theme/variables.scss" as *;
.describePrakerja {
  padding: 0;
  padding-left: 123px;
  position: relative;
  box-shadow: 0 6px 5px #a9a9a940;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  background-color: $NEUTRAL-10;
  padding-block: 55px;
  .mockupImg {
    @include flexCol(1rem);
    position: relative;
    height: 562px;
    width: 443px;
    z-index: 1;

    img {
      object-fit: contain;
    }
    img[alt="prakerja"] {
      position: absolute;
      top: -2rem;
      width: 385px;
      height: 130px;
      flex-shrink: 0;
      z-index: 2;
    }
    img[alt="people-mockup"] {
      position: absolute;
      left: -200px;
      width: 843px;
      height: 562px;
      flex-shrink: 0;
      bottom: -3.5rem;
    }
  }

  .wrapperText {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    z-index: 2;
    h1 {
      color: $NEUTRAL-90;
      font-size: 40px;
      font-weight: 600;
      line-height: 50px;
      padding-bottom: 8px;
      span {
        color: $NEW-PRIMARY2-BASE;
      }
    }
    .text {
      width: 100%;
      display: flex;
      flex-direction: column;
      p {
        color: $NEUTRAL-100;
        font-size: 16px;
        line-height: 24px;
        width: 574px;
      }
      div {
        .linkTo {
          width: max-content;
        }
      }
    }
  }
}
@media (max-width: 1100px) {
  .describePrakerja {
    order: 1;
    padding: 1rem;
    @include flexCol(4rem);
    overflow: hidden;
    @media (max-width: 768px) {
      row-gap: 0;
      align-items: center;
    }
    .mockupImg {
      order: 2;
      height: 540px;
      @media (max-width: 768px) {
        height: 400px;
        top: -30px;
        img[alt="prakerja"] {
          width: 285px;
          height: 80px;
          left: 70px;
          top: 60px !important;
        }
        img[alt="people-mockup"] {
          width: 100%;
          min-width: 460px !important;
          left: 1rem;
          bottom: -50%;
        }
      }
      img[alt="prakerja"] {
        top: -24px;
      }
    }

    .wrapperText {
      row-gap: 24px;
      h1 {
        line-height: 1rem;
        font-size: 24px !important;
      }
    }
    .text {
      p {
        font-size: 14px !important;
        width: auto !important;
        line-height: 20px !important;
      }
    }
    .button {
      align-self: center;
    }
  }
}
